import { Slice } from 'components/ps-chart/models/Slice'
import { TreeNode } from 'components/ps-chart/stores/connections-store/LinksTree/TreeNode'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'
import { PsChartStore } from 'components/ps-chart/PsChartStore'

interface Summary {
  processedTime: number
}

interface ChainedSlice extends Slice {
  links: ChainedSlice[] // Always an array
  summary?: Summary
}

export interface MergedSlice {
  title: string
  summary: Summary
  links: MergedSlice[]
}

export function getChainedSliceTree(node: TreeNode, sliceById: ReadonlySliceById): ChainedSlice {
  // Get the corresponding slice for the current node
  const slice = sliceById.get(node.sliceId)
  if (!slice) {
    throw new Error(`Slice with id ${node.sliceId} not found`)
  }

  // Base case: if no fromLinks, return the slice with an empty links array
  if (!node.fromLinks || node.fromLinks.length === 0) {
    return { ...slice, links: [] } // Always return an array for links
  }

  // Recursive case: create ChainedSlices for all fromLinks
  const links: ChainedSlice[] = node.fromLinks.map((link) => {
    return getChainedSliceTree(link.toTreeNode, sliceById)
  })

  // Return the ChainedSlice with its corresponding links array
  return {
    ...slice,
    links, // 'links' is always an array
  }
}

export function addChainSummary(slice: ChainedSlice): ChainedSlice {
  const ownDuration = slice.end - slice.start

  // Recursively compute the processTime for links and update them
  let linksDuration = 0
  for (let i = 0; i < slice.links.length; i++) {
    // Update the link with summary
    slice.links[i] = addChainSummary(slice.links[i])
    // Accumulate the processTime from the child link
    linksDuration += slice.links[i].summary?.processedTime ?? 0
  }

  const totalDuration = ownDuration + linksDuration

  // Set the processedTime in the summary
  slice.summary = { processedTime: totalDuration }

  return slice
}

function mergeChainedSlices(chainedSlices: ChainedSlice[]): MergedSlice {
  // All slices have the same title
  const { title } = chainedSlices[0]

  // Sum the processTimes
  let totalProcessTime = 0
  for (const slice of chainedSlices) {
    totalProcessTime += slice.summary?.processedTime ?? 0
  }

  // Collect all child links and group them by title
  const childSlicesMap = new Map<string, ChainedSlice[]>()
  for (const slice of chainedSlices) {
    for (const child of slice.links) {
      const childTitle = child.title
      if (!childSlicesMap.has(childTitle)) {
        childSlicesMap.set(childTitle, [])
      }
      childSlicesMap.get(childTitle)!.push(child)
    }
  }

  // Recursively merge child slices
  const mergedLinks: MergedSlice[] = []
  for (const childGroup of childSlicesMap.values()) {
    const mergedChild = mergeChainedSlices(childGroup)
    mergedLinks.push(mergedChild)
  }

  // Return the merged slice
  return {
    title: title,
    summary: { processedTime: totalProcessTime },
    links: mergedLinks,
  }
}

export function generateMergedStatsSlice(
  sliceIds: number[],
  psChartStore: PsChartStore,
): MergedSlice {
  const chainedSlices: ChainedSlice[] = []

  for (const sliceId of sliceIds) {
    // Get the corresponding TreeNode for the sliceId
    const treeNode = psChartStore.traceAnalyzeStore.getLinksTreeBySliceId(sliceId)

    if (!treeNode) {
      throw new Error(`TreeNode not found for sliceId ${sliceId}`)
    }

    // Build the ChainedSlice tree
    let chainedSlice = getChainedSliceTree(treeNode, psChartStore.sliceById)

    // Compute the summary statistics (processTime) and get the updated ChainedSlice
    chainedSlice = addChainSummary(chainedSlice)

    // Collect the ChainedSlices
    chainedSlices.push(chainedSlice)
  }

  // Merge the ChainedSlice trees into a single MergedSlice tree
  const mergedSlices = mergeChainedSlices(chainedSlices)

  return mergedSlices
}
