import { makeAutoObservable } from 'mobx'

import { SearchState } from 'components/ps-chart/stores/SearchState'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'
import { Slice } from 'components/ps-chart/models/Slice'

const NOT_SELECTED_ID = -1

const MAX_RESULTS_COUNT = 20000

export interface SearchData {
  text: string
  id: number
  args: { value: string }[]
  objectId: number
}

export class SearchStore {
  isOpened = false
  autoFocus = true
  inputValue = ''
  private readonly searchState: SearchState
  curResultNum: number = NOT_SELECTED_ID
  curWindowX = 0

  constructor(searchState: SearchState) {
    makeAutoObservable(this)
    this.searchState = searchState
  }

  setIsOpened = (isOpened: boolean) => {
    this.isOpened = isOpened
  }

  setAutoFocus = (autoFocus: boolean) => {
    this.autoFocus = autoFocus
  }

  setInputValue = (inputValue: string) => {
    this.inputValue = inputValue
  }

  resetSearchState(keepContext = false) {
    if (!keepContext) {
      this.searchState.setSearchContextThreadId(null)
    }
    this.searchState.setSearchResults([])
    this.searchState.setSearchCurrentSliceId(null)
    this.setCurResultNum(NOT_SELECTED_ID)
  }

  handleSearchChange = (searchTerm: string, sliceById: ReadonlySliceById) => {
    if (searchTerm.length < 3) {
      this.resetSearchState(true)
    } else {
      this.applySearch(searchTerm, sliceById)
      this.setCurResultNum(NOT_SELECTED_ID)
    }
  }

  onSubmit(searchTerm: string, psChartStore: PsChartStore) {
    this.applySearch(searchTerm, psChartStore.sliceById)
    const results = this.searchState.searchResults

    const { xStart } = psChartStore.hState
    let positionToSelect = SearchStore.getNextSelectPosition(this.curResultNum, results.length)
    const isWindowChanged = xStart !== this.curWindowX
    if (isWindowChanged || this.curResultNum === NOT_SELECTED_ID) {
      positionToSelect = SearchStore.getBestFirstSelectPosition(
        results,
        psChartStore.sliceById,
        xStart,
      )
    }

    this.selectFoundSlicePosition(positionToSelect, psChartStore)
    this.setCurWindowX(xStart)
  }

  /**
   * selectFoundSlicePosition doesn't need to call moveToSlice here - it will be triggered in {@link FlameChart} psChartStore.sortedThreads reaction
   */
  private selectFoundSlicePosition(selectPosition: number, psChartStore: PsChartStore) {
    const results = this.searchState.searchResults
    if (results.length > 0 && selectPosition >= 0) {
      const id = results[selectPosition]
      psChartStore.setSelectedSlice(psChartStore.sliceById.get(id)!)
      this.searchState.setSearchCurrentSliceId(id)
    }
    this.setCurResultNum(selectPosition)
  }

  setCurResultNum(curResultNum: number) {
    this.curResultNum = curResultNum
  }

  stepRight(psChartStore: PsChartStore) {
    const results = this.searchState.searchResults
    const { xStart } = psChartStore.hState
    if (results.length > 0) {
      let positionToSelect = SearchStore.getNextSelectPosition(this.curResultNum, results.length)
      if (this.curResultNum === NOT_SELECTED_ID) {
        positionToSelect = SearchStore.getBestFirstSelectPosition(
          results,
          psChartStore.sliceById,
          xStart,
        )
      }
      this.selectFoundSlicePosition(positionToSelect, psChartStore)
    }
  }

  stepLeft(psChartStore: PsChartStore) {
    const results = this.searchState.searchResults
    const { xStart } = psChartStore.hState
    if (results.length > 0) {
      let positionToSelect = SearchStore.getPrevSelectPosition(this.curResultNum)
      if (this.curResultNum === NOT_SELECTED_ID) {
        positionToSelect = SearchStore.getBestFirstSelectPosition(
          results,
          psChartStore.sliceById,
          xStart,
        )
      }
      this.selectFoundSlicePosition(positionToSelect, psChartStore)
    }
  }

  setCurWindowX(curWindowX: number) {
    this.curWindowX = curWindowX
  }

  applySearch(termToSearch: string, sliceById: ReadonlySliceById) {
    this.searchState.setSearchResults(
      SearchStore.searchTerm(
        Array.from(sliceById.values())
          .filter(
            (slice) =>
              this.searchState.searchContextThreadId == null ||
              this.searchState.searchContextThreadId === slice.threadId,
          )
          .sort((a, b) => a.start - b.start)
          .map((slice) => ({
            text: slice.title.toLocaleLowerCase(),
            id: slice.id,
            args: slice.args,
            objectId: slice.objectId,
          })),
        termToSearch,
        sliceById,
      ),
    )
  }

  static manualSearchResults(termToSearch: string, sliceById: ReadonlySliceById): number[] {
    return SearchStore.searchTerm(
      Array.from(sliceById.values())
        .sort((a, b) => a.start - b.start)
        .map((slice) => ({
          text: slice.title.toLocaleLowerCase(),
          id: slice.id,
          args: slice.args,
          objectId: slice.objectId,
        })),
      termToSearch,
      sliceById,
    )
  }

  static manualSearch(
    termToSearch: string,
    sliceById: ReadonlySliceById,
    currentFrameStartX: number,
  ): Slice | undefined {
    const manualResults = SearchStore.manualSearchResults(termToSearch, sliceById)
    const position = SearchStore.getBestFirstSelectPosition(
      manualResults,
      sliceById,
      currentFrameStartX,
    )

    return sliceById.get(manualResults[position])
  }

  static getNextSelectPosition = (curResultNum: number, resultsLength: number) =>
    Math.min(curResultNum + 1, resultsLength - 1)

  static getPrevSelectPosition = (curResultNum: number) => Math.max(curResultNum - 1, 0)

  static searchTerm = (
    data: SearchData[],
    term: string,
    sliceById: ReadonlySliceById,
  ): number[] => {
    const results: number[] = []
    const lowTerm = term.toLowerCase()
    const idVersion = Number(term)

    if (!Number.isNaN(idVersion)) {
      const slice = sliceById.get(idVersion)
      if (slice != null) {
        results.push(slice.id)
      }
    }

    for (const { text, id, args, objectId } of data) {
      const isSearchInText = text.toLocaleLowerCase().indexOf(lowTerm) !== -1
      const isSearchMatchingObjectId = objectId.toString() === lowTerm
      let isSearchInArgs = false

      if (args?.length > 0) {
        for (const { value } of args) {
          if (value.toLowerCase().includes(lowTerm)) {
            isSearchInArgs = true
            break
          }
        }
      }

      if (isSearchInText || isSearchInArgs || isSearchMatchingObjectId) {
        results.push(id)
      }

      if (results.length === MAX_RESULTS_COUNT) {
        break
      }
    }

    return results
  }

  static getBestFirstSelectPosition = (
    foundSlices: readonly number[],
    sliceById: ReadonlySliceById,
    currentFrameStartX: number,
  ): number => {
    let bestPosition = -1
    let curPosition = 0
    for (const curSliceId of foundSlices) {
      const curSlice = sliceById.get(curSliceId)!
      bestPosition = curPosition
      if (curSlice.end >= currentFrameStartX) {
        break
      }
      curPosition++
    }

    return bestPosition
  }
}
