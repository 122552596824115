import React, { useCallback, useEffect, useState } from 'react'
import {
  generateMergedStatsSlice,
  MergedSlice,
} from 'components/ps-chart/stores/connections-store/LinksTree/getChainedSliceTree'
import { twMerge } from 'tailwind-merge'
import styled from 'styled-components/macro'
import tw from 'twin.macro'
import { SearchStore } from 'components/ps-chart/actions-panel/SearchStore'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import {
  formatNS,
  stopMouseDown,
  TableCardChild,
  TableCardChildButton,
  transformSummary,
} from 'components/cco/StatsView'
import { EndpointSessionData, MethodData } from 'components/cco/types'
import { TableSummaryView } from 'components/cco/TableStateToggleButton'
import { useCcoParams } from 'components/cco/useCcoParams'
import { Link } from 'react-router-dom'

export const TableColumnText = styled.span<{ right: boolean }>`
  direction: ${({ right }) => (right ? 'rtl' : 'ltl')};
`

export const TableColumnContent = styled.div`
  ${tw`flex items-center h-[100%] hover:bg-dark-dark3`}
`

const TableRowContainer = ({
  data,
  containerClassName,
  firstTextClassName,
  otherTextClassName,
  onMouseDown = () => {},
  onMouseUp = () => {},
}: {
  data: string[]
  containerClassName: string
  firstTextClassName: string
  otherTextClassName: string
  onMouseUp: () => void
  onMouseDown: () => void
}) => {
  return (
    <div
      className={twMerge('px-[8px] mb-[2px] rounded-[4px]', 'grid grid-cols-8', containerClassName)}
    >
      {data.length &&
        data.map((item, index) => (
          <div
            key={index}
            className={`${index === 0 ? 'col-span-4 *:truncate' : 'col-span-1'} flex items-center`}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
          >
            <TableColumnText
              className={twMerge('px-[8px]', index === 0 ? firstTextClassName : otherTextClassName)}
              right={index === 0}
            >
              {item}
            </TableColumnText>
          </div>
        ))}
    </div>
  )
}

export const TableEndpointMethodsElement = ({
  data,
  methods,
  sessions,
  summaryViewMode,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  psChartStore,
}: {
  data: string[]
  methods: MethodData[]
  sessions: EndpointSessionData[]
  summaryViewMode: TableSummaryView
  psChartStore: PsChartStore
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [mouseDownTime, setMouseDownTime] = useState(0)

  const handleMouseDown = useCallback(() => {
    setMouseDownTime(Date.now())
  }, [])

  const handleClick = useCallback(() => {
    const duration = Date.now() - mouseDownTime

    // Threshold duration to differentiate between click and text selection
    const threshold = 200 // milliseconds

    if (duration < threshold) {
      setIsOpen((state) => !state)
    }
  }, [mouseDownTime, setIsOpen])

  return (
    <>
      {!isOpen ? (
        <TableRowContainer
          onMouseDown={handleMouseDown}
          onMouseUp={handleClick}
          data={data}
          containerClassName="bg-dark-dark1 hover:bg-dark-dark2 h-[56px]"
          firstTextClassName="text-[16px] leading-[22px] text-gray-service"
          otherTextClassName="text-[12px] leading-[17px] text-gray-service"
        />
      ) : (
        <TableEndpointMethodsCardContainer
          onMouseDown={handleMouseDown}
          onMouseUp={handleClick}
          data={data}
          methods={methods}
          sessions={sessions}
          summaryViewMode={summaryViewMode}
        />
      )}
    </>
  )
}

export const TableEndpointMethodsCardContainer = ({
  data,
  methods,
  sessions,
  summaryViewMode,
  onMouseDown = () => {},
  onMouseUp = () => {},
}: {
  data: string[]
  methods: MethodData[]
  sessions: EndpointSessionData[]
  summaryViewMode: TableSummaryView
  onMouseUp: () => void
  onMouseDown: () => void
}) => {
  const { ccoSessionPath } = useCcoParams()

  const [methodsExpander, setMethodsExpander] = useState(1)
  const [sessionsExpander, setSessionsExpander] = useState(1)

  const expandMethods = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setMethodsExpander((prevExpander) => prevExpander + 1)
    },
    [setMethodsExpander],
  )

  const collapseMethods = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setMethodsExpander(1)
    },
    [setMethodsExpander],
  )

  const expandSessions = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setSessionsExpander((prevExpander) => prevExpander + 1)
    },
    [setSessionsExpander],
  )

  const collapseSessions = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setSessionsExpander(1)
    },
    [setSessionsExpander],
  )

  return (
    <div className="px-[8px] pb-[16px] mb-[2px] rounded-[4px] bg-dark-dark2">
      <div className="h-[56px] border-b-[1px] border-b-[#383838] grid grid-cols-8">
        {data.length &&
          data.map((item, index) => (
            <div
              key={index}
              className={`${
                index === 0 ? 'col-span-4 *:truncate' : 'col-span-1'
              } flex items-center`}
              onMouseDown={onMouseDown}
              onMouseUp={onMouseUp}
            >
              <TableColumnText
                className={twMerge(
                  'px-[8px]',
                  index === 0
                    ? 'text-[16px] leading-[22px] font-semibold'
                    : 'text-[12px] leading-[17px]',
                )}
                right={index === 0}
              >
                {item}
              </TableColumnText>
            </div>
          ))}
      </div>
      <>
        {methods
          .slice(0, 10 * methodsExpander)
          .map(({ title, cpuTime, storage, traffic, count, type }, index) => (
            <TableCardChild
              key={index}
              data={[
                title,
                ...transformSummary({ cpuTime, traffic, storage, count }, summaryViewMode),
              ]}
              methodType={type}
            >
              {methodsExpander > 1 &&
                index === Math.min(methods.length - 1, 10 * methodsExpander - 2) && (
                  <TableCardChildButton onClick={collapseMethods} onMouseDown={stopMouseDown}>
                    Collapse
                  </TableCardChildButton>
                )}
              {methods.length / 10 >= methodsExpander && index === 10 * methodsExpander - 1 && (
                <TableCardChildButton onClick={expandMethods} onMouseDown={stopMouseDown}>
                  Expand
                </TableCardChildButton>
              )}
            </TableCardChild>
          ))}
        {sessions
          .slice(0, 10 * sessionsExpander)
          .map(({ title, cpuTime, storage, traffic, count }, index) => (
            <TableCardChild
              key={index}
              data={[
                <Link to={ccoSessionPath(title)}>{title}</Link>,
                ...transformSummary({ cpuTime, traffic, storage, count }, summaryViewMode),
              ]}
              lastItem={index === Math.min(sessions.length - 1, 10 * sessionsExpander - 1)}
            >
              {index === 0 && (
                <span className="text-[12px] leading-[17px] font-semibold text-white">
                  User Sessions
                </span>
              )}
              {sessionsExpander > 1 &&
                index === Math.min(methods.length - 1, 10 * methodsExpander - 2) && (
                  <TableCardChildButton onClick={collapseSessions} onMouseDown={stopMouseDown}>
                    Collapse
                  </TableCardChildButton>
                )}
              {sessions.length / 10 >= sessionsExpander && index === 10 * sessionsExpander - 1 && (
                <TableCardChildButton onClick={expandSessions} onMouseDown={stopMouseDown}>
                  Expand
                </TableCardChildButton>
              )}
            </TableCardChild>
          ))}
      </>
    </div>
  )
}

export const TableEndpointTreeElement = ({
  data,
  psChartStore,
}: {
  data: string[]
  psChartStore: PsChartStore
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [mouseDownTime, setMouseDownTime] = useState(0)
  const [chainTreeData, setChainTreeData] = useState<MergedSlice | null>(null)

  const handleMouseDown = useCallback(() => {
    setMouseDownTime(Date.now())
  }, [])

  const handleClick = useCallback(() => {
    const duration = Date.now() - mouseDownTime
    const threshold = 200 // milliseconds
    if (duration < threshold) {
      setIsOpen((state) => !state)
    }
  }, [mouseDownTime])

  useEffect(() => {
    if (isOpen) {
      const endpointTitle = data[0] // Adjust based on your data structure
      const sliceIds = SearchStore.manualSearchResults(endpointTitle, psChartStore.sliceById)
      const mergedSlice = generateMergedStatsSlice(sliceIds, psChartStore)
      setChainTreeData(mergedSlice)
    }
  }, [isOpen, data, psChartStore])

  return (
    <>
      {!isOpen ? (
        <TableRowContainer
          onMouseDown={handleMouseDown}
          onMouseUp={handleClick}
          data={data}
          containerClassName="bg-dark-dark1 hover:bg-dark-dark2 h-[56px]"
          firstTextClassName="text-[16px] leading-[22px] text-gray-service"
          otherTextClassName="text-[12px] leading-[17px] text-gray-service"
        />
      ) : (
        <TableEndpointCardContainer
          onMouseDown={handleMouseDown}
          onMouseUp={handleClick}
          data={data}
          chainTreeData={chainTreeData}
        />
      )}
    </>
  )
}

const TableEndpointCardContainer = ({
  data,
  chainTreeData,
  onMouseDown = () => {},
  onMouseUp = () => {},
}: {
  data: string[]
  chainTreeData: MergedSlice | null
  onMouseUp: () => void
  onMouseDown: () => void
}) => {
  return (
    <div className="px-[8px] pb-[16px] mb-[2px] rounded-[4px] bg-dark-dark2">
      {/* Header */}
      <div className="h-[56px] border-b-[1px] border-b-[#383838] grid grid-cols-8">
        {data.map((item, index) => (
          <div
            key={index}
            className={`${index === 0 ? 'col-span-4 truncate' : 'col-span-1'} flex items-center`}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
          >
            <TableColumnText
              className={twMerge(
                'px-[8px]',
                index === 0
                  ? 'text-[16px] leading-[22px] font-semibold'
                  : 'text-[12px] leading-[17px]',
              )}
              right={index === 0}
            >
              {item}
            </TableColumnText>
          </div>
        ))}
      </div>
      {/* Chain Tree */}
      {chainTreeData && (
        <div className="mt-2">
          <ChainTreeView node={chainTreeData} level={0} />
        </div>
      )}
    </div>
  )
}

const ChainTreeView = ({ node, level }: { node: MergedSlice; level: number }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleToggle = () => {
    setIsExpanded((prev) => !prev)
  }

  return (
    <div style={{ paddingLeft: level * 20 }}>
      <div onClick={handleToggle} style={{ cursor: 'pointer' }}>
        {/* Display node title and summary */}
        <span className="font-semibold">{node.title}</span> -{' '}
        <span className="text-gray-500">{formatNS(node.summary.processedTime)}</span>
        {node.links && node.links.length > 0 && (
          <span className="ml-2 text-blue-500">[{isExpanded ? '-' : '+'}]</span>
        )}
      </div>
      {isExpanded &&
        node.links &&
        node.links.map((childNode, index) => (
          <ChainTreeView key={index} node={childNode} level={level + 1} />
        ))}
    </div>
  )
}
